@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

@font-face {
  font-family: "Inter";
  src: url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap"),
    url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("https://fonts.googleapis.com/css2?family=Inter&display=swap"),
    url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//     font-family: 'Proxima Nova Rg';
//     src: url('styles/fonts/ProximaNova-Bold.woff2') format('woff2'),
//         url('styles/fonts/ProximaNova-Bold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Proxima Nova Rg';
//     src: url('styles/fonts/ProximaNova-Regular.woff2') format('woff2'),
//         url('styles/fonts/ProximaNova-Regular.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

:root {
  --grey-text-login: #4f4f4f;
  --primary: #00acee !important;
  --primary-light: #69c8ed;
  --bold-blue: #262f56;
  --secondary: #f5f0dd !important;
  --grey-border: #bdbdbd;
  --light-grey: #f8f8f8;
  --black-text: #282828;
  --orange-badge: #4dbce9;
  --red: #ff5c58;
  --primary-hover: #5bc0e8 !important;
  --green-box: #72be42;
  --yellow-box: #fbc02a;
}

body {
  margin: 0;
  // font-family: 'Proxima Nova Rg', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  // overflow: hidden;
  font-size: 18px;
  position: relative;
  color: #1c1c1c !important;
}

::-webkit-scrollbar {
  height: 20px;
}
::-webkit-scrollbar-track {
  background: rgb(228, 228, 228);
}
::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
  border-radius: 10px;
  border: 4px solid rgb(228, 228, 228);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: rgba(255, 0, 221, 0.863);
  font-size: 16px;
  // color: rgba(255, 162, 0, 0.842);
}

a {
  text-decoration: none !important;
  color: #1c1c1c !important;
}

span {
  text-decoration: none !important;
}

select {
  font-size: 12px !important;
}

p {
  font-size: 12px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.text-main {
  color: var(--primary) !important;
}

.text-grey {
  color: #9e9e9e !important;
}

.text-green {
  color: #71be42 !important;
}

.text-white {
  color: #ffffff !important;
}

.MuiTextField-root {
  border-radius: 4px !important;
  border: 1px solid #ededed !important;
  background: var(--field-bg, #fbfbfb) !important;
  min-height: 46px !important;
}

.MuiFormControl-root {
  border: none !important;
  min-height: 46px !important;
}

.MuiOutlinedInput-notchedOutline {
  margin-top: 5px !important;
  border: 1px solid var(--field-stroke, #e6e9ed) !important;
  min-height: 46px !important;
}

.MuiOutlinedInput-input {
  min-height: 26px !important;
}

#mui-pickers-date {
  padding: 0.5rem !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
  display: flex !important;
  align-items: center !important;
}

.MuiListItem-button:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12.5px 14px !important;
}

/* input file */
.wrap-border-file {
  // border: 1px dashed var(--primary, #00ACEE);
  border-radius: 5px;
  padding: 32px;
  // background: rgba(229, 248, 255, 0.50) !important;
}
._input-file {
  position: relative;
  width: 100%;
  background: rgba(229, 248, 255, 0.5) !important;
  border: 2px dashed var(--primary);
  border-radius: 4px;
}
._input-file:hover {
  cursor: pointer !important;
}
._input-file input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}
._input-file p {
  color: var(--grey-text-login);
}
._input-file .placeholder-file {
  font-size: 14px;
  color: var(--grey-text-login);
  margin: 12px 0 0;
}
._input-file .container-preview::after {
  content: " ";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #0000001a;
  border-radius: 4px;
}
._input-file .img-preview {
  border-radius: 4px;
  max-height: 200px;
  min-height: 100px;
  object-fit: cover;
}
._input-file .preview-active .MuiSvgIcon-root,
._input-file .preview-active .placeholder-file {
  color: #fff !important;
}

._input-file .content {
  height: 150px;
  width: 50px;
}

.btntab {
  width: "auto";
  background-color: #ffffff;
  color: #757575 !important;
  margin-right: 10;
  border: 1px solid #e0e0e0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.btntab.active {
  background-color: var(--primary) !important;
  color: #ffffff !important;
  border: none !important;
}

.gap-24 {
  gap: 24px !important;
}

.w-full {
  width: 100% !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-10 {
  font-size: 10px !important;
  color: #9e9e9e !important;
  margin-top: 4px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.ml-33 {
  margin-left: 33px !important;
}

.p-38 {
  padding: 38px !important;
}

.text-right {
  text-align: right !important;
}

.custom-success-button {
  background-color: #75bc43;
  color: #fff;
  border: none;
  font-size: 20px;
  font-weight: 700;
  width: 160px;
  padding: 10px 15px;
  margin: 30px 10px;
  border-radius: 10px;
  font-style: normal;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
  cursor: pointer;
}

.custom-success-button:hover {
  background-color: #75c13f;
}

.custom-error-button {
  background-color: #f04438;
  color: #fff;
  border: none;
  font-size: 20px;
  font-weight: 700;
  width: 160px;
  padding: 10px 15px;
  margin: 30px 10px;
  border-radius: 10px;
  font-style: normal;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
  cursor: pointer;
}

.custom-error-button:hover {
  background-color: #e63b32;
}

.custom-swal-title {
  margin-top: 25px;
  font-size: 20px;
}

.custom-swal-title-out {
  margin-top: 25px;
  margin-bottom: 50px;
}

.custom-swal-confirm-out,
.custom-swal-cancel-out {
  width: 138px;
  height: 41px;
  padding: 10px, 16px, 10px, 16px;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  margin: 30px 10px;
  border: 1px solid #00acee;
}

.custom-swal-confirm-out {
  background-color: #fff;
  color: #00acee;
}

.custom-swal-confirm-out:hover {
  background-color: #00acee;
  color: #fff;
}

.custom-swal-cancel-out {
  background-color: #00acee;
  color: #fff;
}

.custom-swal-cancel-out:hover {
  background-color: #009bd8;
}

textarea {
  width: 100%;
  display: flex;
  height: 83px;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #e6e9ed;
  background: #fbfbfb;
}

textarea:-moz-placeholder, /* Firefox 18- */
textarea::-moz-placeholder, /* Firefox 19+ */
textarea:-ms-input-placeholder, /* IE 10+ */
textarea::-webkit-input-placeholder, /* Webkit based */
textarea::placeholder {
  /* Modern browsers */
  color: #9e9e9e;
}

.h1-title {
  font-weight: 700 !important;
  margin: 0 !important;
  font-size: 30px !important;
}

.h2-subtitle {
  font-weight: 700 !important;
  margin: 0 !important;
  // font-size: calc(1.3rem + 0.6vw);
  font-size: 24px !important;
}

.back-button {
  display: none;
}

.table-cell {
  width: 150px;
  //   width: 100%;
  height: 50px;
  left: 0px;
  top: 0px;
  border-radius: 5px;
  border: 1px;
}

.table-cell-row {
  width: 75px;
  //   width: 100%;
  height: 50px;
  left: 0px;
  top: 0px;
  border-radius: 5px;
  border: 1px;
}

.table-cell-binaan {
  width: 125px;
  //   width: 100%;
  height: 50px;
  left: 0px;
  top: 0px;
  border-radius: 5px;
  border: 1px;
}

.vote-date-text {
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
}

.leaflet-container {
  width: 100%;
  height: 70vh;
  z-index: 0;
}

.leaflet-maps {
  width: 100%;
  height: 60vh;
  border-radius: 5px;
  border: 1px solid #e6e9ed;
  background: #fbfbfb;
  margin-bottom: 10px;
}

.table-res {
  min-width: 1050px;
  overflow: scroll;

  @for $width from 1 through 1000 {
    // You can customize the range as needed
    .table-cell-#{$width}px {
      width: #{$width}px;
      //   height: 50px;
      //   border-radius: 5px;
      //   border: 1px solid black; // Add a color here
    }
  }
}

.leaflet-tooltip {
  font-weight: 600;
}

.wrapper-partai {
  color: white;
  padding: 20px;
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
  font-weight: 500;
}

.centering-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sebaran-partai {
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}

.sebaran-partai:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.sebaran-partai-mobile {
  padding: 10px;
  font-size: 18px;
  // background-color: #009bd8;
  color: white;
  // transform: rotate(180deg);
  text-align: center;
}

.button-pills {
  padding: 2px 10px;
  border-radius: 20px;
}

// MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button

.select-area-partai .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid #000 !important;
  border-radius: 10px !important;
}

.select-area-partai .MuiOutlinedInput-root {
  border-radius: 10px;
}

.select-area-partai .MuiOutlinedInput-input {
  min-height: 0 !important;
}

// class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled"

// MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined

.select-area-partai .MuiFormLabel-root.Mui-focused {
  color: rgba(74, 70, 109, 0.54);
  font-weight: 600;
  font-size: 16px;
  background-color: #fff;
  padding: 4px;
  margin-top: -5px;
}

.select-area-partai
  .MuiFormLabel-root.MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: rgba(74, 70, 109, 0.54);
  font-weight: 600;
  font-size: 16px;
  background-color: #fff;
  padding: 4px;
  margin-top: -5px;
}

.select-area-partai .MuiInputBase-root {
  background-color: #fff;
  font-size: 15px;
  font-weight: 500;
  color: rgba(74, 70, 109, 0.54);
}

.MuiAutocomplete-listbox {
  scrollbar-width: none; /* Firefox */
}

.MuiAutocomplete-option {
  font-size: 15px;
  font-weight: 500;
  color: rgba(74, 70, 109, 0.54);
  letter-spacing: 1px;
}

.select-area-partai {
  width: 300px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 10px;
  padding-top: 12px;
  background-color: #fff;
  border-radius: 0px 5px 5px 5px;
}

@media screen and (max-width: 425px) {
  .h1-title {
    font-size: 20px !important;
  }

  .h2-subtitle {
    font-size: 18px !important;
  }

  .back-button {
    display: block;
  }

  .grid-container-mobile-reverse {
    display: flex;
    // flex-direction: column-reverse;
    align-self: flex-start;
    margin: 0;
  }

  .grid-container-between-mobile {
    display: flex;
    justify-content: space-between;
  }

  .p-filter-select {
    padding: 0 3px !important;
  }

  .vote-date-text {
    font-size: 14px;
    font-weight: 500;
  }
}

.text-center {
  text-align: center !important;
}
